<template>
    <section class="main">
        <div class="main-top">
            <h1>Dashboard</h1>
            <i class="fas fa-user-cog"></i>
        </div>
        <div class="users">
            <div class="card">
                <h4>Total Records:</h4>
                <div class="per">
                    <table>
                        <tr>
                            <td><span>{{ total }}</span></td>
                        </tr>
                    </table>
                 </div>
            </div>
            <div class="card">
            <h4>SRITI BBST:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ totalBbst }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <h4>SRITI KOTA WARISAN:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ totalKotaWarisan }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        </div>
<br>
<div class="main-top">
            <br>
            <h1>Kelas (SRITI Kota Warisan)</h1>
            <i class="fas fa-user-cog"></i>
        </div>
        <div class="users">
            <div class="card">
                <h4>1 Al-Khawarizmi:</h4>
                <div class="per">
                    <table>
                        <tr>
                            <td><span>{{ kw_1Khawarizmi }}</span></td>
                        </tr>
                    </table>
                 </div>
            </div>
            <div class="card">
            <h4>2 Al-Farabi:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ kw_2Farabi }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <h4>3 Al-Razi:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ kw_3Razi }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        </div>
<br>
        <div class="main-top">
            <br>
            <h1>Kelas (SRITI BBST)</h1>
            <i class="fas fa-user-cog"></i>
        </div>
        <div class="users">
            <div class="card">
                <h4>1 Abu Bakar:</h4>
                <div class="per">
                    <table>
                        <tr>
                            <td><span>{{ bbst_1Abubakar }}</span></td>
                        </tr>
                    </table>
                 </div>
            </div>
            <div class="card">
            <h4>1 Umar:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ bbst_1Umar }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <h4>2 Uthman:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ bbst_2Uthman }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <h4>2 Hamzah:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ bbst_2Hamzah }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <h4>3 Ali:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ bbst_3Ali }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <h4>4 Salman:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ bbst_4Salman }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <h4>4 A.Rahman:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ bbst_4AbdulRahman }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <h4>5 Abu Ubaidah:</h4>
            <div class="per">
                <table>
                    <tr>
                        <td><span>{{ bbst_5AbuUbaidah }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        </div>
        <br>
        

        <section class="attendance">
            <div class="attendance-list">
                <h1>Order List</h1>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>SRITI</th>
                            <th>Kelas</th>
                            <th>Jantina</th>
                            <th>Rumah Sukan</th>
                            <th>Saiz</th>
                            <th>Amount (RM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Ahmad</td>
                            <td>ahmad@admah.co</td>
                            <td>BBST</td>
                            <td>1 Ali</td>
                            <td>Lelaki</td>
                            <td>Rumah Biru</td>
                            <td>38</td>
                            <td>35</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
        
    
</section>
</template>

<script>
import axios from 'axios'

const api = axios.create({
  baseURL: 'https://fastapi-sritisukan2023-github.herokuapp.com/' // Replace with your actual API endpoint
});


export default {
    data() {
        return {
            total: null,
            totalBbst: null,
            totalKotaWarisan: null,
            kw_1Khawarizmi: null,
            kw_2Farabi: null,
            kw_3Razi: null,
            bbst_1Abubakar: null,
            bbst_1Umar: null,
            bbst_2Uthman: null,
            bbst_2Hamzah: null,
            bbst_3Ali: null,
            bbst_4Salman: null,
            bbst_4AbdulRahman: null,
            bbst_5AbuUbaidah: null
        }
    },
    mounted() {
        const fetchData = () => {
            Promise.all([
                api.get('/orders/total'),
                api.get('/orders/total/bbst'),
                api.get('/orders/total/kotawarisan'),
                api.get('/orders/total/kotawarisan/1Khawarizmi'),
                api.get('/orders/total/kotawarisan/2Farabi'),
                api.get('/orders/total/kotawarisan/3Razi'),
                api.get('/orders/total/bbst/1Abubakar'),
                api.get('/orders/total/bbst/1Umar'),
                api.get('/orders/total/bbst/2Uthman'),
                api.get('/orders/total/bbst/2Hamzah'),
                api.get('/orders/total/bbst/3Ali'),
                api.get('/orders/total/bbst/4Salman'),
                api.get('/orders/total/bbst/4AbdulRahman'),
                api.get('/orders/total/bbst/5AbuUbaidah'),

                
            ])
            .then(responses => {
                this.total = responses[0].data.total
                this.totalBbst = responses[1].data.total
                this.totalKotaWarisan = responses[2].data.total
                this.kw_1Khawarizmi = responses[3].data.total
                this.kw_2Farabi = responses[4].data.total
                this.kw_3Razi = responses[5].data.total
                this.bbst_1Abubakar = responses[6].data.total
                this.bbst_1Umar = responses[7].data.total
                this.bbst_2Uthman = responses[8].data.total
                this.bbst_2Hamzah = responses[9].data.total
                this.bbst_3Ali = responses[10].data.total
                this.bbst_4Salman = responses[11].data.total
                this.bbst_4AbdulRahman = responses[12].data.total
                this.bbst_5AbuUbaidah = responses[13].data.total
            

            })
            .catch(error => {
                console.error(error)
            })
        }
        fetchData()
        setInterval(fetchData,30000)
        
    },
    
    
}


</script>

<style>
.container{
  display: flex;
}
*{
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body{
  background: rgb(226, 226, 226);
}

a{
  position: relative;
  width: 280px;
  font-size: 14px;
  color: rgb(85, 83, 83);
  display: table;
  padding: 10px;
}
nav .fas{
  position: relative;
  width: 70px;
  height: 40px;
  top: 20px;
  font-size: 20px;
  text-align: center;
}
.nav-item{
  position: relative;
  top: 12px;
  margin-left: 10px;
}
a:hover{
  background: #eee;
}
a:hover i{
  color: #34AF6D;
  transition: 0.5s;
}
.logout{
  position: absolute;
  bottom: 0;
}

.container{
  display: flex;
}

/* MAin Section */
.main{
  position: relative;
  padding: 20px;
  width: 100%;
}
.main-top{
  display: flex;
  width: 100%;
}
.main-top i{
  position: absolute;
  right: 0;
  margin: 10px 30px;
  color: rgb(110, 109, 109);
  cursor: pointer;
}
.main .users{
  display: flex;
  width: 100%;
}
.users .card{
  width: 25%;
  margin: 10px;
  background: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}
.users .card img{
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.users .card h4{
  text-transform: uppercase;
}
.users .card p{
  font-size: 12px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.users table{
  margin:  auto;
}
.users .per span{
  padding: 5px;
  border-radius: 10px;
  background: rgb(223, 223, 223);
}
.users td{
  font-size: 14px;
  padding-right: 15px;
}
.users .card button{
  width: 100%;
  margin-top: 8px;
  padding: 7px;
  cursor: pointer;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #4AD489;
}
.users .card button:hover{
  background: #4AD489;
  color: #fff;
  transition: 0.5s;
}

/*Attendance List serction  */
.attendance{
  margin-top: 20px;
  text-transform: capitalize;
}
.attendance-list{
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}
.table{
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 15px;
  min-width: 100%;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
table thead tr{
  color: #fff;
  background: #34AF6D;
  text-align: left;
  font-weight: bold;
}
.table th,
.table td{
  padding: 12px 15px;
}
.table tbody tr{
  border-bottom: 1px solid #ddd;
}
.table tbody tr:nth-of-type(odd){
  background: #f3f3f3;
}
.table tbody tr.active{
  font-weight: bold;
  color: #4AD489;
}
.table tbody tr:last-of-type{
  border-bottom: 2px solid #4AD489;
}
.table button{
  padding: 6px 20px;
  border-radius: 10px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #4AD489;
}
.table button:hover{
  background: #4AD489;
  color: #fff;
  transition: 0.5rem;
}

</style>